<template>
  <div>
    <h3 class="text-center">Add Area of Expertise</h3>
    <ImagPicker
      :imageData="{ maxNoImg: 1, imageNo: 0 }"
      class="margin-20-bottom"
    ></ImagPicker>
    <p v-if="areaOfExpertiesError.icon" class="text-center" style="color: red">
       Icon Can't be empty
    </p>
    <div class="margin-20-bottom">
      <b-form-input
        placeholder="Title"
        v-model="areaOfExperties.header"
      ></b-form-input>
      <p
        v-if="areaOfExpertiesError.header"
        class="text-center"
        style="color: red"
      >
         Title Can't be empty
      </p>
    </div>
    <div>
          
    
      <quill-editor v-model="areaOfExperties.detail"
                ref="myQuillEditor"
                :options="editorOption"
                >
  </quill-editor>
    <p
      v-if="areaOfExpertiesError.detail"
      class="text-center"
      style="color: red"
    >
       Detail Can't be empty
    </p>

   
    </div>


    <a href=""></a>
    <br />
    <div class="horizontal-center">
      <b-button variant="success" @click="addAreaOfExperties"><b-spinner v-if="loading"  small></b-spinner>Save</b-button>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

import { postFields } from "../../assets/js/service";
import ImagPicker from "../Common/ImageUpload.vue";
export default {
  components: {
    ImagPicker
  },
  data() {
    return {
      areaOfExperties: {
        detail: null,
        icon: null,
        header: null,
      },
      areaOfExpertiesError: {
        detail: false,
        icon: false,
        header: false,
      },
      bold:false,
      italic:false,
      detail: null,loading: false,
      linkText: null,
      link: null,
    };
  },
  methods: {
    changeHtmlElement() {
      detail.split("");
    },
    textBold(){
      if(!this.bold){
this.areaOfExperties.detail += '<b>'
        this.bold =true
      }else{
        this.areaOfExperties.detail += '</b>'
        this.bold = false
      }

    },
      textItalic(){
      if(!this.italic){
this.areaOfExperties.detail += '<i>'
        this.italic =true
      }else{
        this.areaOfExperties.detail += '</i>'
        this.italic = false
      }

    },
    addBreak(){
this.areaOfExperties.detail += '<br>'
    },
    addLink() {
      if (this.link && this.linkText) {
        this.areaOfExperties.detail =
          this.areaOfExperties.detail +
          '<a href="' +
          this.link +
          '">' +
          this.linkText +
          "</a>";
      }
    },
    addAreaOfExperties() {
        if(!this.areaOfExperties.detail){
            this.areaOfExpertiesError.detail = true
        }else{
            this.areaOfExpertiesError.detail = false
        }
         if( !this.$store.state.images ||
            this.$store.state.images.length == 0){
            this.areaOfExpertiesError.icon = true
        }else{
            this.areaOfExperties.icon =  this.$store.state.images;
            this.areaOfExpertiesError.icon = false
        }
         if(!this.areaOfExperties.header){
            this.areaOfExpertiesError.header = true
        }else{
            this.areaOfExpertiesError.header = false
        }
      if (
        this.areaOfExperties.detail &&
        this.areaOfExperties.icon &&
        this.areaOfExperties.header
      ) {this.$store.commit("set", ["loading", true]);
        let token = localStorage.getItem("token");
        postFields("areaOfExperties", this.areaOfExperties, token).then(
          (resp) => {this.$store.commit("set", ["loading", false]);
              this.areaOfExperties = {
                     detail: null,
        icon: null,
        header: null,
              }
            Vue.$toast.success("Successful", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        );
      }
    },
  },
};
</script>
<style>
</style>